import React, {useEffect} from "react"
import { Link } from "gatsby"
import * as CustomerCaseStyle from "./CustomerCase.module.scss"
import { slugify } from "../../../utils/slugify"
const CustomerCase = ({ item }) => {


  return (
    <section>
      <div
        className={`${"container xl-container loadin "} ${
          CustomerCaseStyle.Container
        }`}
      >
        <div>
          <div className={CustomerCaseStyle.author}>
          <p>{item.attributes.description}</p>
          </div>
          <div
            className={`${CustomerCaseStyle.author} ${CustomerCaseStyle.job}`}
          >
            <p className="fs-sm">
              {item.attributes.author}, <em>{item.attributes.job}</em>
            </p>
          </div>
          <Link
            to={"/cas-clients/" + slugify(item.attributes.client)}
            className="button primary"
          >
            Voir le cas client
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CustomerCase
