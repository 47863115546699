import React from "react"
import Layout from "../components/Layouts/Layout/Layout"
import ContentBuilder from "../components/sections/ContentBuilder"
import CustomerCase from "../components/sections/CustomerCase/CustomerCase"
import componentFactory from "../utils/componentFactory"
import Seo from "../components/seo"
import TitleBlock from "../components/Blocks/TitleBlock/TitleBlock"

const AreaTemplate = ({ pageContext }) => {
  const page = pageContext.page.attributes
  const item = pageContext.item.attributes
  const components = page.components.map(i => componentFactory(i, pageContext))
  const areas = pageContext.customerCaseByArea.data.strapi.areas.data.filter(
    i => i.id === pageContext.item.id
  )
  const area = areas.length > 0 ? areas[0] : undefined

  return (
    <>
      <Layout>
        <TitleBlock title={pageContext.item.attributes.label} />
        {item.content1.map((content1, index) => {
          return (
            <ContentBuilder
              key={index}
              html={content1.html}
              color={content1.color}
              background_image={content1.background_image}
            />
          )
        })}
        {area?.attributes.customer_cases.data.length > 0 ? (
          <CustomerCase item={area.attributes.customer_cases.data[0]} />
        ) : (
          <></>
        )}

        {item.content2.map((content2, index) => {
          return (
            <ContentBuilder
              key={index}
              html={content2.html}
              color={content2.color}
              background_image={content2.background_image}
            />
          )
        })}
        {components}
      </Layout>
    </>
  )
}
export const Head = ({ pageContext }) => (
  <Seo
    title={"Secteur : " + pageContext.item.attributes.label}
    description={pageContext.item.attributes.description}
  />
)
export default AreaTemplate
